/*
 * @Author: your name
 * @Date: 2021-11-13 10:10:19
 * @LastEditTime: 2021-11-16 10:39:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\video-monitor.js
 */
import request from '@/utils/request.js';
/**
 * 获取摄像机
 * @returns {Promise}
 */
export function getCamera(params) {
  return request({
    url: '/traffic-construction/device/getCameraChannel',
    method: 'get',
    params
  });
}
export function getTotal(params) {
  return request({
    url: '/roses-platform/device/getCameraNumberByOrgIdAndStatus',
    method: 'get',
    params
  });
}
/* // 方向控制
export function cameraDirectionControl(params) {
  return request({
    url: '/roses-platform/cameraAction/cameraDirectionControl',
    method: 'get',
    params
  });
} */
// 缩放控制
/* export function cameraZoomControl(params) {
  return request({
    url: '/roses-platform/cameraAction/cameraZoomControl',
    method: 'get',
    params
  });
} */
// 其他机构
export function getOther(params) {
  return request({
    url: '/roses-platform/cameraWindowDisplay/getOtherOgrCarmeraListByOrgId',
    method: 'get',
    params
  });
}
// 获取机构树
export function getOrgTree(params) {
  return request({
    url: '/traffic-construction/device/list',
    method: 'get',
    params
  });
}
// 获取机构树
export function changeOrder(data) {
  return request({
    url: '/traffic-construction/device/editSort',
    method: 'post',
    data
  });
}
// 通过isc平台播放摄像头获取流地址
export function getPreviewURLs(params) {
  return request({
    url: '/roses-platform/cameraAction/iscPlay',
    method: 'get',
    params
  });
}
// 通过isc平台-云台控制
export function cameraDirectionControl(params) {
  return request({
    url: '/roses-platform/cameraAction/controlling',
    method: 'get',
    params
  });
}