<!--
 * @Author: your name
 * @Date: 2021-11-09 16:56:42
 * @LastEditTime: 2021-12-07 11:09:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\visualization\video-control\contro-dialog.vue
-->
<template>
  <el-dialog
    width="930px"
    :title="headerText"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="120px" :model="paramsForm" :rules="rules" v-loading="dialogLoading">
      <el-form-item label="所属机构">
        <span slot="label" v-if="!isDisabled"
          ><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>上级分类</span
        >
        <tree-select
          :data="grantTree"
          :defaultProps="defaultProps"
          nodeKey="id"
          :isDisabled="isDisabled"
          :checkedKeys="defaultCheckedKeys"
          @popoverHide="popoverHide"
        ></tree-select>
      </el-form-item>
      <el-form-item label="系统唯一标识" prop="sysUqNo">
        <el-input
          v-model="paramsForm.sysUqNo"
          placeholder="请输入系统唯一标识"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="摄像头名称" prop="deviceName">
        <el-input
          v-model="paramsForm.deviceName"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备小类" prop="deviceTypeId">
        <el-select
          v-model="paramsForm.deviceTypeId"
          :disabled="isDisabled"
          filterable
          @change="getDeviceType"
          placeholder="请选择或输入以搜索"
          style="width: 300px"
        >
          <el-option v-for="item in classList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="国标设备编号" prop="deviceNoNational">
        <el-input
          v-model="paramsForm.deviceNoNational"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="视频通道编号" prop="channelNo1">
        <el-input
          v-model="paramsForm.channelNo1"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备型号" prop="deviceModel">
        <el-input
          v-model="paramsForm.deviceModel"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备状态" prop="status">
        <el-select
          v-model="paramsForm.status"
          :disabled="isDisabled"
          filterable
          placeholder="请选择或输入以搜索"
          style="width: 300px"
        >
          <el-option v-for="item in statuList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- 未写 -->
      <el-form-item label="MN号" prop="mn">
        <el-input v-model="paramsForm.mn" :disabled="isDisabled" placeholder="请输入" style="width: 300px"></el-input>
      </el-form-item>
      <!-- 未写 -->
      <el-form-item label="设备IP" prop="deviceIp">
        <el-input
          v-model="paramsForm.deviceIp"
          :disabled="isDisabled"
          placeholder="请输入"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="为隧道摄像头" prop="isTunnel">
        <el-select
          v-model="paramsForm.isTunnel"
          :disabled="isDisabled"
          placeholder="请选择是否为隧道摄像头"
          style="width: 300px"
        >
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="协议方式" prop="agreeType">
        <el-select
          v-model="paramsForm.agreeType"
          :disabled="isDisabled"
          placeholder="请选择摄像头协议"
          style="width: 300px"
        >
          <el-option label="GB28181(默认)" :value="0"></el-option>
          <el-option label="萤石云" :value="1"></el-option>
          <el-option label="乐橙云" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="第三方账号" prop="sysDictDataId" v-if="otherType">
        <el-select
          v-model="paramsForm.sysDictDataId"
          :disabled="isDisabled"
          placeholder="请选择第三方账号"
          style="width: 300px"
        >
          <el-option v-for="item in SysDictData" :key="item.id" :label="item.code" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备序列号" prop="deviceSerialNumber" v-if="otherType">
        <el-input
          v-model="paramsForm.deviceSerialNumber"
          :disabled="isDisabled"
          placeholder="请输入设备序列号"
          style="width: 300px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="deleteFunc" type="danger" v-if="isDisabled && $check('video-control_delete')">删 除</el-button>
      <el-button @click="openDetail" type="primary" v-if="isDisabled && $check('video-control_edit')">修 改</el-button>
      <el-button type="primary" v-if="!isDisabled" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  getDeviceTypeList,
  getSystemUniqueNo,
  getTree,
  postAdd,
  postEdit,
  deleteRickPoint,
  SysDictDataByCamera
} from '@/api/general-user/video-control.js';
import { getItem } from '@/utils/storage.js';
import TreeSelect from '@/components/treeSelect/index.vue';

export default {
  props: {
    headerText: {
      type: String
    }
  },
  components: {
    TreeSelect
  },
  data() {
    return {
      visible: false,
      paramsForm: {
        sysUqNo: '',
        orgId: '',
        orgName: '',
        deviceTypeName: ''
      },
      valueTitle: '',
      rules: {
        deviceNoNational: [{ required: true, message: '请输入国标设备编号', trigger: 'blur' }],
        channelNo1: [{ required: true, message: '请输入视频通道编号', trigger: 'blur' }],
        orgId: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
        deviceTypeId: [{ required: true, message: '请选择设备小类', trigger: 'change' }],
        status: [{ required: true, message: '请选择设备状态', trigger: 'change' }],
        deviceModel: [
          { required: true, message: '请输入设备型号', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符之内', trigger: 'blur' }
        ],
        sysUqNo: [
          { required: true, message: '请输入系统唯一标识', trigger: 'blur' },
        ],
        deviceName: [
          { required: true, message: '请输入摄像头名称', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符之内', trigger: 'blur' }
        ],
        isTunnel: [{ required: true, message: '请选择是否隧道摄像头', trigger: 'change' }],
        agreeType: [{ required: true, message: '请选择摄像头协议', trigger: 'change' }],
        sysDictDataId: [{ required: true, message: '请选择第三方账号', trigger: 'change' }],
        deviceSerialNumber: [{ required: true, message: '请输入设备序列号', trigger: 'blur' }]
      },
      orgList: [],
      classList: [],
      grantTree: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      statuList: [
        { id: 0, name: '可用' },
        { id: 1, name: '不可用' },
        { id: 2, name: '删除' }
      ],
      confirmLoading: false,
      isDisabled: false,
      dialogLoading: false,
      tenantId: '',
      isShowSelect: true,
      deteleData: {},
      SysDictData: []
    };
  },
  created() {
    this.tenantId = getItem('tenantInfo').tenantId;
  },
  methods: {
    open(row) {
      this.deteleData = row;
      this.visible = true;
      this.getGrantTreeData();
      this.getSysDictDataByCamera();
      this.getDeviceTypeList();
      if (row) {
        this.isDisabled = true;
        for (const key in this.rules) {
          this.rules[key][0].required = false;
        }
        this.paramsForm = row;
        setTimeout(() => {
          this.defaultCheckedKeys = [row.orgId];
        }, 500);
      } else {
        for (const key in this.rules) {
          this.rules[key][0].required = true;
        }
        // this.getSystemUniqueNo();
      }
    },
    async getGrantTreeData() {
      try {
        const res = await getTree();
        this.grantTree = res.data;
      } finally {
      }
    },
    async getSysDictDataByCamera() {
      try {
        let tenantId = this.tenantId;
        let appType = '1';
        let query = { tenantId, appType };
        const res = await SysDictDataByCamera(query);
        this.SysDictData = res.data;
      } finally {
      }
    },
    // 获取所选设备id数组
    getCheckedDeviceList(data, list) {
      this.paramsForm.orgName = data.name;
      this.paramsForm.orgId = data.id;
    },
    getSystemUniqueNo() {
      getSystemUniqueNo({ orgId: this.tenantId }).then(res => {
        if (res.code !== 200) {
          return;
        }
        this.paramsForm.sysUqNo = res.data;
      });
    },
    // 获取小类下拉
    getDeviceTypeList() {
      getDeviceTypeList({ categoryId: '1275687182924779523' }).then(res => {
        if (res.code !== 200) {
          return;
        }
        this.classList = res.data;
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.isDisabled = false;
      this.paramsForm = this.$options.data().paramsForm;
      this.grantTree = [];
      this.defaultCheckedKeys = [];
    },
    deleteFunc() {
      this.$confirm('请确认是否删除该摄像头', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteRickPoint();
      });
    },
    openDetail() {
      this.dialogLoading = true;
      this.isDisabled = false;
      this.$emit('update:headerText', '修改摄像头');
      for (const key in this.rules) {
        this.rules[key][0].required = true;
      }
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    getDeviceType(id) {
      this.classList.map(item => {
        if (item.id == id) {
          this.paramsForm.deviceTypeName = item.typeName;
        }
      });
    },
    // 选择上级分类
    popoverHide(checkedIds, checkedData) {
      this.paramsForm.orgName = checkedData.name;
      this.paramsForm.orgId = checkedData.id;
      // this.checkedData = checkedData
    },
    //删除接口
    deleteRickPoint() {
      deleteRickPoint({ ...this.deteleData }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.handleColse();
          this.$emit('on-success');
        }
      });
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        if (!this.paramsForm?.id) {
          postAdd(this.paramsForm)
            .then(res => {
              this.$message.success('新增成功！');
              this.handleColse();
              this.$emit('on-success',1);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        } else {
          postEdit(this.paramsForm)
            .then(res => {
              this.$message.success('编辑成功！');
              this.handleColse();
              this.$emit('on-success',1);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    }
  },
  computed: {
    otherType() {
      return !!this.paramsForm.agreeType;
    }
  }
};
</script>
<style lang="less" scoped>
.mask {
  width: 100px;
  height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
}
</style>
