<template>
  <div v-loading="loading" class="org-tree" :style="{ width: isVideoM ? '500px' : '240px' }">
    <div class="title">机构目录</div>
    <el-input placeholder="输入关键字进行过滤" v-model="filterText" clearable></el-input>

    <div class="tree-wrapper">
      <el-tree
        draggable
        @node-drop="handleDrop"
        ref="orgTreeRef"
        node-key="id"
        default-expand-all
        highlight-current
        :data="orgTree"
        :props="defaultProps"
        :expand-on-click-node="isVideoM ? true : false"
        :filter-node-method="filterNode"
        @node-click="onNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div v-if="!isVideoM">
            <i class="treeIcon" :class="data.icon"></i>
            {{ data.name }}
          </div>
          <div v-if="isVideoM" :style="{ color: data.offAll ? 'red' : '' }">
            {{ data.name + '（' + data.nums + '）' }}
          </div>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<script>
// import { getOrgTree, changeOrder } from '@/api/common/index.js';
import { getOrgTree, changeOrder } from '@/api/general-user/video-monitor.js';

export default {
  props: {
    isVideoM: {
      required: false,
      type: Boolean,
      defaultL: false
    }
  },
  data() {
    return {
      loading: false,
      filterText: '',
      orgTree: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  created() {
    this.getOrgTreeData();
  },
  methods: {
    getOrgTreeData() {
      this.loading = true;
      getOrgTree()
        .then(res => {
          if (!res.success) {
            // this.$message(res.message);
            return;
          }
          this.orgTree = res.data;
          if (this.isVideoM) {
            this.countNums(this.orgTree, {});
            this.countNums(this.orgTree, {}, true);
            if (!this.orgTree[0].nums && this.orgTree[0].nums !== 0) {
              this.orgTree.map(item => {
                item.nums = 0;
                item.children.map(item1 => {
                  item.nums += item1.nums;
                });
              });
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    countNums(tree, parent, isUp = false) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];
        if (item.children.length) {
          this.countNums(item.children, tree[i], isUp);
        } else {
          if (isUp) {
            parent.nums = 0;
            parent.children.map(item2 => {
              if (item2.children.length) {
                item2.nums = 0;
                item2.children.map(item3 => {
                  item2.nums += item3.nums;
                });
              }
              parent.nums += item2.nums;
            });
          } else {
            parent.nums = tree.length;
            parent.offAll = true;
            parent.children.map(item1 => {
              if (!item1.isDevice && !item1.cameraStatus) {
                parent.offAll = false;
                return;
              }
            });
            parent.isOrg = true;
            parent.children = [];
          }
          return;
        }
      }
    },
    // 节点被点击时回调
    onNodeClick(data, a) {
      this.$emit('node-click', data);
    },
    // 重置状态，取消高亮
    reset() {
      this.$refs.orgTreeRef.setCurrentKey(null);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      if (draggingNode.data.pid !== dropNode.data.pid || dropType === 'inner') {
        this.getOrgTreeData();
        return this.$message.error('只允许同级机构进行排序');
      } else {
        const params = {};
        params.orgId = draggingNode.data.pid;
        params.id = draggingNode.data.id;
        params.sort = draggingNode.data.sort; // 变更前sort
        params.newSort = dropNode.data.sort; // 变更后sort
        // params.sort = dropNode.data.sort;
        this.postChangeOrder(params);
        // console.log(params);
      }
    },
    postChangeOrder(params) {
      changeOrder(params)
        .then(res => {
          this.$emit('on-sort');

          this.$message.success('排序成功！');
        })
        .catch(err => {
          this.getOrgTreeData();
        });
    }
  },
  watch: {
    filterText(val) {
      this.$refs.orgTreeRef.filter(val);
    }
  }
};
</script>

<style lang="less" scoped>
.org-tree {
  box-sizing: border-box;
  width: 240px;
  height: 90%;
  // border: 1px solid #dcdfe7;
  margin-right: 26px;

  .title {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 12px;
    background: #f3f6fe;
    color: #303133;
  }

  .tree-wrapper {
    height: calc(100vh - 320px);
    padding: 16px 0;
    overflow: auto;

    .el-tree {
      box-sizing: border-box;
      padding: 0 16px;
      min-width: 100%;
      display: inline-block;
    }
  }
}
.treeIcon {
  color: red;
  margin-right: 5px;
}
</style>
