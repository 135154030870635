<!--
 * @Author: your name
 * @Date: 2021-11-09 16:15:38
 * @LastEditTime: 2021-12-07 11:11:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\visualization\video-control\index.vue
-->
<template>
  <div>
    <div class="flex">
      <org-tree ref="treeRef" :isVideoM="true" @node-click="handleNodeClick" @on-sort="onSort"></org-tree>
      <div style="width: calc(100vw - 850px);">
        <!-- <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="90px">
          <el-form-item prop="categoryId" label="所属机构">
            <el-select v-model="queryForm.orgId" placeholder="请选择" filterable clearable>
              <el-option
                v-for="item in orgList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="摄像头名称">
            <el-input v-model="queryForm.deviceName" placeholder="请输入摄像头名称" style="width:280px"></el-input>
          </el-form-item>
          <el-form-item label="摄像头编号">
            <el-input v-model="queryForm.deviceModel" placeholder="输入摄像头编号" style="width:280px"></el-input>
          </el-form-item>
          <el-form-item label="是否隧道摄像头" label-width="120px">
            <el-select v-model="queryForm.isTunnel" placeholder="请选择">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form> -->
        <el-button icon="el-icon-refresh-right" @click="refresh">
          刷新
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin-bottom: 16px"
          v-if="$check('video-control_add')"
          @click="handleAdd"
          >新增摄像头</el-button
        >
        <el-table v-loading="tableLoading" :data="controlList" row-key="id">
          <el-table-column fixed align="center" type="index" label="序号"></el-table-column>
          <!-- <el-table-column
        align="center"
        width="300"
        prop="orgName"
        label="所属机构"
        show-overflow-tooltip
      ></el-table-column> -->
          <el-table-column align="center" prop="deviceName" label="摄像头名称" show-overflow-tooltip></el-table-column>
          <!--  <el-table-column
        align="center"
        width="300"
        prop="deviceModel"
        label="设备型号"
        show-overflow-tooltip
      ></el-table-column> -->
          <el-table-column align="center" prop="isTunnel" label="是否为隧道摄像头" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ scope.row.isTunnel | tunnelText }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="cameraStatus" label="在线状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <span class="el-icon-warning" :style="{ color: scope.row.cameraStatus === 0 ? 'green' : 'red' }"></span>
                {{ scope.row.cameraStatus | statusText }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作">
            <template slot-scope="{ row }">
              <div>
                <span class="action-btn" @click="handleGrant(row.id)">查看详情</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="queryForm.pageNo"
          :page-size="queryForm.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="total, sizes, prev, pager, next"
        ></el-pagination>
        <!-- 新增编辑 -->
        <contro-dialog ref="controRef" :headerText.sync="headerText" @on-success="onSuccess"></contro-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import controDialog from './controDialog.vue';
import { getList, getOrgList, postDetail } from '@/api/general-user/video-control.js';
import OrgTree from '@/components/VideoTree';

export default {
  components: {
    controDialog,
    OrgTree
  },
  data() {
    return {
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        deviceName: '',
        orgId: '',
        deviceModel: '',
        isTunnel: ''
      },
      orgList: [],
      tableLoading: false,
      controlList: [],
      headerText: '新增摄像头',
      timer:null
    };
  },
  created() {
    this.getList();
    // this.getOrgList();
    this.timer=setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods: {
    getOrgList() {
      getOrgList().then(res => {
        if (res.code !== 200) {
          return;
        }
        this.orgList = res.data;
      });
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getList();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getList();
    },
    getList() {
      this.tableLoading = true;
      getList(this.queryForm)
        .then(res => {
          this.total = res.data.totalRows;
          this.controlList = res.data.rows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.queryForm = this.$options.data().queryForm;
      this.handleSearch();
      this.$refs.treeRef.getOrgTreeData();
    },
    handleAdd() {
      this.headerText = '新增摄像头';
      this.$refs.controRef.open();
    },
    onSuccess(id) {
      if (!id) {
        this.handleReset();
      } else {
        // this.getList();
        this.refresh();
      }
    },
    handleGrant(id) {
      postDetail({ id }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.message);
          return;
        }
        this.headerText = '查看详情';
        this.$refs.controRef.open(res.data);
      });
    },
    handleNodeClick(data) {
      if (data.isOrg) {
        this.queryForm.orgId = data.id;
        this.getList();
      }
      // console.log(data.isOrg);
    },
    onSort() {
      this.$refs.treeRef.getOrgTreeData();
    },
    refresh() {
      this.getList();
      this.$refs.treeRef.getOrgTreeData();
    }
  },
  filters: {
    tunnelText(val) {
      return val === 1 ? '是' : '否';
    },
    statusText(val) {
      return val === 0 ? '在线' : '离线';
    }
  }
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
</style>
